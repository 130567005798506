import React, { useState, useEffect, useContext } from "react";
import { withRouter } from "react-router-dom";
import { Container, Row, Col, Table, Spinner, Badge } from "react-bootstrap";
import "react-bootstrap-typeahead/css/Typeahead.css";
import "../../styles/api.css";
import JSONPretty from 'react-json-prettify';
import {atomOneLight} from 'react-json-prettify/dist/themes';
import {
  MdCheckCircle,
} from "react-icons/md";


function ApiDocContentComp(props) {
  const [pageLoading, setPageLoading] = useState(false);
  const [activeDiv, setActiveDiv] = useState(props.data);
  const [proId, setProId] = useState(
    localStorage.getItem("selected_project")
      ? localStorage.getItem("selected_project")
      : "pr_123456789abcde"
  );

  useEffect(() => {
    console.log(props.data)
    let bodyDiv = document.getElementsByClassName("main-body");
    bodyDiv[0].style.backgroundColor = "#1D2125";
    setActiveDiv(props.data);
    if (activeDiv) {
      document.getElementById(activeDiv).scrollIntoView(15);
    }
  }, [props.data,activeDiv,props.valChanged]);

  const copyToClipBoard = (id) => {
    let str = document.getElementById(id).innerHTML;
    var doc = new DOMParser().parseFromString(str, 'text/html');
    var string = doc.body.textContent;
    const el = document.createElement('textarea');
    el.value = string;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
    document.getElementById(id+"-copied").style.display="flex";
    setTimeout(function(){
      document.getElementById(id+"-copied").style.display="none";
    },2000);
  };

  const handleScroll = () => {
    var all = document.getElementsByClassName("api-heading");
      for (var i = 0, max = all.length; i < max; i++) {
        if (isHidden(all[i])) {
          var elements = document.getElementsByClassName("active-menu");
          while(elements.length > 0){
            elements[0].classList.remove('active-menu');
          }
          document.getElementById(all[i].id+'-btn').classList.add("active-menu");
          break;
        }
      }

      function isHidden(el) {
        const rect = el.getBoundingClientRect();
        return (
          rect.top >= 0 &&
          rect.left >= 0 &&
          rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
          rect.right <= (window.innerWidth || document.documentElement.clientWidth)
        );
      }
  };

  const goToErrorSection = () => {
    document.getElementById('error-codes').scrollIntoView(15);
  }

  const customTheme = {
    ...atomOneLight,
    background: '#263238',
    valueQuotes: 'rgb(140, 153, 165)',
    value: {
        ...atomOneLight.value,
        string: '#8aa5d1',
    },
    brace: 'rgb(129, 211, 204)',
    colon: 'rgb(129, 211, 204)',
    // key: 'rgb(201, 70, 56)',
};

  const sendSMSJson = {
    "id": "1234567890ABC",
    "project_id": proId,
    "accepted_at": "2021-09-14T02:47:56.332",
    "direction": "OUTGOING",
    "api_type": "CAMPAIGN",
    "from": "SerendibSys",
    "to": "+94710000000",
    "body": "sample sms",
    "length": 6,
    "segments": 1,
    "cost": 0,
    "currency": "LKR",
    "status": "SENT",
    "number_metadata": {
      "dial_code": "94",
      "region": "LK",
      "local_number": "710000000",
      "number_type": "MOBILE"
    },
    "mode": "TEST",
    "events": {
      "accepted_at": "2021-09-14T02:47:56.332",
      "queued_at": "2021-09-14T02:48:00.007",
      "sent_at": "2021-09-14T02:48:00.218"
    },
    "status_callback": {}
  }

  const smsDetailsJson = {
    "id": "1234567890ABC",
    "project_id": proId,
    "accepted_at": "2021-09-14T02:47:56.332",
    "direction": "OUTGOING",
    "api_type": "CAMPAIGN",
    "from": "SerendibSys",
    "to": "+94710000000",
    "body": "sdfgdf",
    "length": 6,
    "segments": 1,
    "cost": 0,
    "currency": "LKR",
    "status": "SENT",
    "number_metadata": {
      "dial_code": "94",
      "region": "LK",
      "local_number": "710000000",
      "number_type": "MOBILE"
    },
    "mode": "TEST",
    "events": {
      "accepted_at": "2021-09-14T02:47:56.332",
      "queued_at": "2021-09-14T02:48:00.007",
      "sent_at": "2021-09-14T02:48:00.218"
    },
    "status_callback": {}
  }

  const smsLogJson = {
    "total_results": 3,
    "total_pages": 1,
    "page_num": 0,
    "page_size": 100,
    "results": [
      {
        "id": "sms_0000000000000000001",
        "project_id": proId,
        "accepted_at": "2021-09-14T06:14:16.747",
        "direction": "OUTGOING",
        "api_type": "CAMPAIGN",
        "from": "SerendibSys",
        "to": "+94710000000",
        "segments": 1,
        "status": "SENT",
        "mode": "LIVE"
      },
      {
        "id": "sms_0000000000000000002",
        "project_id": proId,
        "accepted_at": "2021-09-14T06:07:51.004",
        "direction": "OUTGOING",
        "api_type": "CAMPAIGN",
        "from": "SerendibSys",
        "to": "+94710000001",
        "segments": 1,
        "status": "DELIVERED",
        "mode": "LIVE"
      },
      {
        "id": "sms_0000000000000000003",
        "project_id": proId,
        "accepted_at": "2021-09-14T06:03:51.455",
        "direction": "OUTGOING",
        "api_type": "CAMPAIGN",
        "from": "SerendibSys",
        "to": "+94710000002",
        "segments": 1,
        "status": "ACCEPTED",
        "mode": "LIVE"
      }
    ]
  }

  return (
    <>
      <div className="api-cus-container">
        <Row className="m-0 cus-content-div api-cus-content-div" onScroll={handleScroll} >
          <Col className="p-0">
            {pageLoading ? (
              <div className="loading-container">
                <Spinner animation="border" size="md" />
              </div>
            ) : (
              <>
                <div
                  style={{ position: "relative", margin: "auto", paddingLeft: "3%", paddingRight: "3%" }}
                >
                  <h5 className="api-heading" id="introduction">Introduction</h5>
                  <div className="api-det-full-div">
                    <div className="api-intro-det-div">
                      <p className="api-description-p">The SMS160 API is RESTful and built on HTTP. It uses a simple and resource oriented naming scheme for endpoints. POST endpoints generally accept HTTP form parameters and may occasionally accept JSON. All endpoints will return a JSON response with standard HTTP response codes. In the event of an error, an appropriate HTTP response code and a more descriptive error message will be returned.</p>
                      <p className="api-description-p">This documentation page will provide information on all supported endpoints and their expected parameters.</p>
                    </div>
                  </div>
                  <hr />
                  
                  <h5 className="api-heading" id="authentication">Authentication</h5>
                  <div className="api-det-full-div">
                    <div className="api-intro-det-div">
                      <p className="api-description-p">The SMS160 API uses HTTP Basic authentication for the REST API where the Project id is used as the username and the Project API key is used as the password.</p>
                      <p className="api-sub-heading">If using curl, you may specify the credentials as follows</p>
                      <div class="method-example-response full-response">
                        <div class="method-example-response-topbar">
                          <div class="method-example-response-title">
                            <p className="api-request-heading">
                              <Badge bg="warning" text="dark" className="get-label">POST</Badge> &nbsp;/v1/login
                            </p>
                            <i className="fas fa-clone copy-icon" onClick={() => copyToClipBoard('get-authentivation-details-curl')}></i>                            
                          </div>
                        </div>
                        <div className="method-response-content">                          
                          <p id="get-authentivation-details-curl">
                            <div style={{marginLeft:'20px'}}>curl -u {'<project id> : <project api key>'}</div>
                          </p>
                        </div>
                        <div className="copied-div" id="get-authentivation-details-curl-copied">
                          <MdCheckCircle className="api-copied" />
                          Copied
                        </div>
                      </div>
                      <p className="parameter-description-p">
                        <p><div className="api-parameter-title">If adding as an HTTP Header:</div></p>
                        <div class="method-example-response full-response">
                          <div class="method-example-response-topbar">
                            <div class="method-example-response-title">
                              <p className="api-request-heading">
                              </p>
                              <i className="fas fa-clone copy-icon" onClick={() => copyToClipBoard('get-authentication-basic-credentials')}></i>
                            </div>
                          </div>
                          <div className="method-response-content">
                            <p id="get-authentication-basic-credentials">
                              <div style={{marginLeft:'20px'}}>Authorization : Basic {'<credentials>'}</div>
                            </p>
                          </div>
                          <div className="copied-div" id="get-authentication-basic-credentials-copied">
                            <MdCheckCircle className="api-copied" />
                            Copied
                          </div>
                        </div>
                        <div>where "credentials" = Base64({'<project id>:<api key>'})</div>
                      </p>
                    </div>
                  </div>
                  <hr />

                  <h5 className="api-heading" id="test-mode">Test Mode</h5>
                  <div className="api-det-full-div">
                    <div className="api-intro-det-div">
                      <p className="api-description-p">The API supports 2 modes for testing and production usage so you can test your integration at no cost. To test using TEST mode, provide the Test Api Key as the password for the authentication header. For LIVE mode, provide the Live Api Key as the authentication password.</p>
                    </div>
                  </div>
                  <hr />

                  <h5 className="api-heading" id="error-codes">Error Codes</h5>
                  <div className="api-det-full-div">
                    <div className="api-intro-det-div">
                      <table className="error-code-table">
                        <tr className="error-code-table-header even-row">
                          <td style={{width:'15%'}}>HTTP Code</td>
                          <td style={{width:'15%'}}>Server Code</td>
                          <td>Description</td>
                        </tr>
                        <tr className="error-code-row">
                          <td>500</td>
                          <td>1000</td>
                          <td>"There was an unexpected error while processing the request."</td>
                        </tr>
                        <tr className="error-code-row even-row">
                          <td>404</td>
                          <td>1001</td>
                          <td>"The requested resource could not be found."</td>
                        </tr>
                        <tr className="error-code-row">
                          <td>400</td>
                          <td>1002</td>
                          <td>"Invalid argument. Please provide all required request parameters."</td>
                        </tr>
                        <tr className="error-code-row even-row">
                          <td>400</td>
                          <td>1004</td>
                          <td>"API Key does not match resource type. This typically happens when requesting a live resource using a test api key or vice versa."</td>
                        </tr>
                        <tr className="error-code-row">
                          <td>400</td>
                          <td>1007</td>
                          <td>"Invalid date time format. Please use yyyy-MM-dd'T'HH:mm:ss or yyyy-MM-dd."</td>
                        </tr>
                        <tr className="error-code-row even-row">
                          <td>400</td>
                          <td>2000</td>
                          <td>"Invalid phone number"</td>
                        </tr>
                        <tr className="error-code-row">
                          <td>400</td>
                          <td>2001</td>
                          <td>"Invalid phone number format"</td>
                        </tr>
                        <tr className="error-code-row even-row">
                          <td>400</td>
                          <td>2002</td>
                          <td>"Phone number does not include country code. Please provide full number including the international dialing code."</td>
                        </tr>
                        <tr className="error-code-row">
                          <td>400</td>
                          <td>3000</td>
                          <td>"From number or alpha id is not valid"</td>
                        </tr>
                        <tr className="error-code-row even-row">
                          <td>400</td>
                          <td>3002</td>
                          <td>"Capability not supported by sender id."</td>
                        </tr>
                        <tr className="error-code-row">
                          <td>400</td>
                          <td>3004</td>
                          <td>"SMS body too long. The maximum allowed length is 1600 characters or 10 segments."</td>
                        </tr>
                        <tr className="error-code-row even-row">
                          <td>401</td>
                          <td>4003</td>
                          <td>"Invalid credentials"</td>
                        </tr>
                        <tr className="error-code-row">
                          <td>400</td>
                          <td>4005</td>
                          <td>"Insufficient account balance. Please recharge your account to start sending."</td>
                        </tr>
                      </table>
                    </div>
                  </div>
                  <hr />
                  <h5 className="api-heading" id="send-sms">Send SMS</h5>
                  <div className="api-det-full-div">
                    <div className="api-det-div">
                      <p className="api-description-p">Send an sms message</p>
                      <p className="api-sub-heading">Request Parameters</p>
                      <hr className="api-sub-heading-hr"/>
                      <p className="parameter-description-p">
                        <div className="api-parameter-title">to <font color="#d46305">{"{"}Required{"}"}</font></div>
                        <div>Recipient phone number</div>
                      </p>
                      <hr />
                      <p className="parameter-description-p">
                        <div className="api-parameter-title">from <font color="#d46305">{"{"}Required{"}"}</font></div>
                        <div>From number or alpha code</div>
                      </p>
                      <hr />
                      <p className="parameter-description-p">
                        <div className="api-parameter-title">body <font color="#d46305">{"{"}Required{"}"}</font></div>
                        <div>Message body</div>
                      </p>
                      <hr />
                      <p className="parameter-description-p">
                        <div className="api-parameter-title">status_callback</div>
                        <div>Callback URL for sms delivery status</div>
                      </p>
                      <hr />
                      <p className="api-sub-heading">Response</p>
                      <hr className="api-sub-heading-hr"/>
                      <p className="api-response-description-p">Reurns the SMS object as JSON. Would return an <font style={{cursor:'pointer'}} onClick={() => goToErrorSection()} color="#276ee4">error</font> if something goes wrong.</p>
                    </div>
                    <div className="api-sample-div">
                      <div class="method-example-response">
                        <div class="method-example-response-topbar">
                          <div class="method-example-response-title">
                            <p className="api-request-heading">
                              <Badge bg="warning" text="dark" className="get-label">POST</Badge> &nbsp;/v1/messages
                            </p>
                            <i className="fas fa-clone copy-icon" onClick={() => copyToClipBoard('send-sms-curl')}></i>
                          </div>
                        </div>
                        <div className="method-response-content" style={{overflowX:"scroll"}}>
                          <p id="send-sms-curl">
                            <div>curl <span className="curl-link">https://api.sms160.io/v1/messages \</span></div>
                            <div style={{marginLeft:'20px'}}>-u pr_fgdheyj23j : test_jskjsdnksjnk7sj \</div>
                            <div style={{marginLeft:'20px'}}>-F to='+123456' \</div>
                            <div style={{marginLeft:'20px'}}>-F body='Hello world' \</div>
                            <div style={{marginLeft:'20px'}}>-F from='SerendibSys' \</div>
                            <div style={{marginLeft:'20px'}}>-F project_id='{proId}' \</div>
                            <div style={{marginLeft:'20px'}}>-F mode='LIVE'</div>
                          </p>
                        </div>
                        <div className="copied-div" id="send-sms-curl-copied">
                          <MdCheckCircle className="api-copied" />
                          Copied
                        </div>
                      </div>
                      <div class="method-example-2-response">
                        <div class="method-example-2-response-topbar">
                          <div class="method-example-2-response-title">Response Body</div>
                        </div>
                        <div className="method-response-2-content">
                          <p>
                            <JSONPretty json={sendSMSJson} theme={customTheme}/>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr />
                  
                  <h5 className="api-heading" id="get-sms-details">Get SMS Details</h5>
                  <div className="api-det-full-div">
                    <div className="api-det-div">
                      <p className="api-description-p">Get details about an SMS that was either sent as a single or bulk message</p>
                      <p className="api-sub-heading">Request Parameters</p>
                      <hr className="api-sub-heading-hr"/>
                      <p className="parameter-description-p">
                      <div className="api-parameter-title">id <font color="#d46305">{"{"}Required{"}"}</font></div>
                        <div>SMS Id</div>
                      </p>
                      <hr />
                      <p className="api-sub-heading">Response</p>
                      <hr className="api-sub-heading-hr"/>
                      <p className="api-response-description-p">Reurns the SMS detail object as JSON. Would return an <font style={{cursor:'pointer'}} onClick={() => goToErrorSection()} color="#276ee4">error</font> if something goes wrong.</p>
                    </div>
                    <div className="api-sample-div">
                      <div class="method-example-response">
                        <div class="method-example-response-topbar">
                          <div class="method-example-response-title">
                            <p className="api-request-heading">
                              <Badge bg="warning" text="dark" className="get-label">GET</Badge> &nbsp;/v1/messages/{'{id}'}
                            </p>
                            <i className="fas fa-clone copy-icon" onClick={() => copyToClipBoard('get-message-details-curl')}></i>
                          </div>
                        </div>
                        <div className="method-response-content">
                          <p id="get-message-details-curl">
                            <div>curl <span className="curl-link">https://api.sms160.io/v1/messages/{'{id}'} \</span></div>
                            <div style={{marginLeft:'20px'}}>-u pr_fgdheyj23j : test_jskjsdnksjnk7sj</div>
                          </p>
                        </div>
                        <div className="copied-div" id="get-message-details-curl-copied">
                          <MdCheckCircle className="api-copied" />
                          Copied
                        </div>
                      </div>
                      <div class="method-example-2-response">
                        <div class="method-example-2-response-topbar">
                          <div class="method-example-2-response-title">Response Body</div>
                        </div>
                        <div className="method-response-2-content">
                          <p>
                            <JSONPretty json={smsDetailsJson} theme={customTheme}/>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <h5 className="api-heading" id="get-sms-log">Get SMS Log</h5>
                  <div className="api-det-full-div">
                    <div className="api-det-div">
                      <p className="api-description-p">Get SMS logs for a given time range</p>
                      <p className="api-sub-heading">Request Parameters</p>
                      <hr className="api-sub-heading-hr"/>                      
                      <p className="parameter-description-p">
                        <div className="api-parameter-title">from</div>
                        <div>Date from in yyyy-MM-dd format</div>
                      </p>
                      <hr />
                      <p className="parameter-description-p">
                        <div className="api-parameter-title">to</div>
                        <div>Date to in yyyy-MM-dd format</div>
                      </p>
                      <hr />
                      <p className="parameter-description-p">
                        <div className="api-parameter-title">page_num <font color="#d46305">{"{"}Required{"}"}</font></div>
                        <div>Page number</div>
                      </p>
                      <hr />
                      <p className="parameter-description-p">
                        <div className="api-parameter-title">page_size <font color="#d46305">{"{"}Required{"}"}</font></div>
                        <div>Page size</div>
                      </p>
                      <hr />
                      <p className="parameter-description-p">
                        <div className="api-parameter-title">sort_order <font color="#d46305">{"{"}Required{"}"}</font></div>
                        <div>DESC for descending by date and ASC for ascending</div>
                      </p>
                      <hr />
                      <p className="api-sub-heading">Response</p>
                      <hr className="api-sub-heading-hr"/>
                      <p className="api-response-description-p">Reurns the SMS log object as JSON. Would return an <font style={{cursor:'pointer'}} onClick={() => goToErrorSection()} color="#276ee4">error</font> if something goes wrong.</p>
                    </div>
                    <div className="api-sample-div">
                      <div class="method-example-response">
                        <div class="method-example-response-topbar">
                          <div class="method-example-response-title">
                            <p className="api-request-heading">
                              <Badge bg="warning" text="dark" className="get-label">GET</Badge> &nbsp;/v1/messages
                            </p>
                            <i className="fas fa-clone copy-icon" onClick={() => copyToClipBoard('get-message-log-curl')}></i>
                          </div>
                        </div>
                        <div className="method-response-content">
                          <p id="get-message-log-curl">
                            <div>curl <span className="curl-link">https://api.sms160.io/v1/messages \</span></div>
                            <div style={{marginLeft:'20px'}}>-u pr_fgdheyj23j : test_jskjsdnksjnk7sj</div>
                            <div style={{marginLeft:'20px'}}>-F page_num='1' \</div>
                            <div style={{marginLeft:'20px'}}>-F page_size='25' \</div>
                            <div style={{marginLeft:'20px'}}>-F sort_order='DESC' \</div>
                          </p>
                        </div>
                        <div className="copied-div" id="get-message-log-curl-copied">
                          <MdCheckCircle className="api-copied" />
                          Copied
                        </div>
                      </div>
                      <div class="method-example-2-response">
                        <div class="method-example-2-response-topbar">
                          <div class="method-example-2-response-title">Response Body</div>
                        </div>
                        <div className="method-response-2-content">
                          <p>
                            <JSONPretty json={smsLogJson} theme={customTheme}/>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </Col>
        </Row>
      </div>
    </>
  );
}

export default withRouter(ApiDocContentComp);